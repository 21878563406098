<template>
  <v-app style="background-color:#eee" >

    <v-dialog v-model="showProgress" persistent max-width="300">
      <v-card>
        <v-card-title>
          Baixando arquivo...
        </v-card-title>
        <v-card-text>
          <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>





  <v-container style="background-color:#eee"  
    id="campanha"
    fluid
    tag="section">
   
    
 

   
    <v-tabs background-color="#eee"

    v-model="tab" centered
    icons-and-text
    color="deep-purple-accent-4"
    align-tabs="center"
  >

  <v-tabs-slider style="background-color:orange !important; "></v-tabs-slider>

    <v-tab href="#tab-1" style="color:#3055a4;">Contratos <v-icon>mdi-file-document-edit</v-icon></v-tab>
    <v-tab href="#tab-2" style="color:#3055a4;">Cobrança <v-icon>mdi-cash-refund</v-icon></v-tab>
    <v-tab href="#tab-3" style="color:#3055a4;">Faturamento <v-icon>mdi-finance</v-icon></v-tab>
    <v-tab href="#tab-4" style="color:#3055a4;">Atendimento <v-icon>mdi-face-agent</v-icon></v-tab>
    <v-tab href="#tab-5" style="color:#3055a4;">Vendas <v-icon>mdi-sale</v-icon></v-tab>

  </v-tabs>

  
  <v-tabs-items v-model="tab" style="background-color:transparent !important">
    <br/><br/><br/>
    <v-tab-item value="tab-1">
      <div>
        <v-row>

       

            <v-container style="background-color:#f8f9fb;" >
              <v-col cols="12">
                <filtro-empresa 
                @filtrar="filterDataEmpresa" 
                  ></filtro-empresa>
              </v-col>
            </v-container>

      

        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="cyan" 
          icon="mdi-barcode"
          subIcon="mdi-information"
          subIconColor="cyan"
          title="Sem Parcelas"
          :valuex="this.bool_07"
          :value="this.total_ativo_parcela_vencer"
          :sub-text="'Contratos Ativos sem Parcela a Vencer'"
          :downloadFunction="lista_ativos_parcelas_vencer_xls"
        />
        </v-col>


        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="red" 
          icon="mdi-coffin"
          subIcon="mdi-information"
          subIconColor="red"
          title="Total de Titular Falecido"
          :valuex="this.bool_08"
          :value="this.total_titular_falecido"
          :sub-text="'Total de Contratos com Titular Falecido'"
          :downloadFunction="lista_titular_falecido_xls"
        />
        </v-col>


        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="red" 
          icon="mdi-file-sign"
          subIcon="mdi-information"
          subIconColor="red"
          title="Informações Incompletas"
          :valuex="this.bool_09"
          :value="this.total_problemas_doc"
          :sub-text="'Contratos com Problemas no dados (Email, Cpf, End....)'"
          :downloadFunction="lista_problemas_doc_xls"
        />
        </v-col>


        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="green" 
          icon="mdi-file-sign"
          subIcon="mdi-information"
          subIconColor="green"
          title="Contratos em Carência"
          :valuex="this.bool_10"
          :value="this.total_servicos_carencia"
          :sub-text="'Serviços em Contratos com Carência e/ou Inadimplêntes'"
          :downloadFunction="lista_servico_carencia_xls"
        />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="orange" 
          icon="mdi-cash"
          subIcon="mdi-information"
          subIconColor="orange"
          title="Pagamentos sem Juros"
          :valuex="this.bool_11"
          :value="this.total_pago_sem_juros"
          :sub-text="'Total de Pagamentos sem Juros'"
          :downloadFunction="lista_contratos_pago_sem_juros"
        />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="green" 
          icon="mdi-cash-register"
          subIcon="mdi-information"
          subIconColor="green"
          title="Caixa Aberto"
          :valuex="this.bool_12"
          :value="this.total_caixa_aberto"
          :sub-text="'Total de Caixas em Aberto'"
          :downloadFunction="lista_caixa_aberto_xls"
        />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="cyan" 
          icon="mdi-ticket"
          subIcon="mdi-information"
          subIconColor="cyan"
          title="Ticket Aberto"
          :valuex="this.bool_13"
          :value="this.total_ticket_aberto"
          :sub-text="'Total de Ticket em Aberto + 15 dias'"
          :downloadFunction="lista_ticket_aberto_xls"
        />
        </v-col>
      

        <v-col
          cols="12"
          sm="6"
          lg="4">
          
          <base-material-stats-card-s elevation="1"
          color="cyan" 
          icon="mdi-ticket"
          subIcon="mdi-information"
          subIconColor="cyan"
          title="Pagamentos a Menor"
          :valuex="this.bool_14"
          :value="this.total_pagamento_menor"
          :sub-text="'Total de Pagamentos a Menor'"
          :downloadFunction="lista_pagamento_menor_xls"
        />
        </v-col>
        
        </v-row>
    
    
        <v-row>
    
          <v-col
          cols="12">
    
              <base-material-card
              icon="mdi-account-cancel"
              title="Clientes Falecidos - (Contrato Ativo) "
              color="green" style="margin-top:20px !important; padding-top:10px !important"
              class="px-5 py-0"
                > 
    
                    <v-data-table
                    :headers="clientes_falecidos_header"
                    :items="clientes_falecidos"
                    :items-per-page="10" 
                    items-per-page-text="Linhas por página"
                    class="elevation-0">
            
    
                              <template v-slot:item="row">
                                
                                <tr :class="{'gray-row': row.index % 2 !== 0}">
                                  <td style="width: 10%;font-size:14px;">{{row.item.idCliente}} </td>
                                  <td style="width: 30%;font-size:14px;">{{row.item.nome}} </td>
                                  <td style="width: 10%;font-size:14px;">{{row.item.contrato}} </td>
                                  <td style="width:12%;font-size:14px;">
                                    <v-chip 
                                    :color="getStatusC(row.item.situacaoContrato)"
                                    text-color="white">
                                        {{row.item.situacaoContrato}}                    
                                        
                                    </v-chip>  
                                  </td>
                                  <td style="width: 30%;font-size:14px;">{{row.item.nascimento}} </td>
                                  <td style="width: 30%;font-size:14px;">{{row.item.falecimento}} </td>
    
                                  
                                </tr>
                            </template>
                    </v-data-table>
    
    
          <br/>
      
        
    
                </base-material-card>
        
          </v-col>
    
        
    
        </v-row>
      </div>
    </v-tab-item>
    <v-tab-item value="tab-2">
      <div>
        <v-row>

          <v-container style="background-color:#f8f9fb;" >
            <v-col cols="12">
              <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
              @filtrar="filterData" :title_opcao="'Campanha'" :campos="'S'"
                ></filtro-component>
            </v-col>
          </v-container>
      
<v-row>
    
    <v-col
    cols="12">


        <base-material-card
        icon="mdi-account-group"
        title="Renegociação - Composição"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 


        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar ID do Acordo"
          single-line
          hide-details
        ></v-text-field>
        <br/>

            <v-data-table
              :headers="lista_composicao_header"
              :items="lista_composicao"
              :items-per-page="20" 
              items-per-page-text="Linhas por página"
              group-by="id"
              show-group-by
              :search="search"
              class="elevation-0">
      

                        <template v-slot:item="row">
                         

                        <tr style="background-color:#eeeeee">
                          <td v-if="row.index === 0" style="width:15%;font-size:12px">Data do Acordo:
                                                      {{ formatarData(row.item.data_acordo) }}</td>

                          <td v-if="row.index === 0" style="width: 20%;font-size:12px">Total da Composição:
                            {{
                              parseFloat(row.item.soma_parcela_original).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>

                          <td v-if="row.index === 0" style="width: 15%;font-size:12px"> Total da Reneg:
                            {{
                              parseFloat(row.item.total_reneg).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>


                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip :color="getEmpresa(row.item.empresa)" text-color="white">
                              {{row.item.empresa}}                    
                            </v-chip>
                          </td>

                          <td  v-if="row.index === 0" style="font-size:12px">Qtd. Parcelas Geradas:{{row.item.parcelamento}}</td>

                          <td v-if="row.index === 0" style="width: 20%;font-size:12px"> Total Pago:
                            {{
                              parseFloat(row.item.total_pago).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>

                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip outlined :color="getStatus(row.item.situacao)" >
                              {{row.item.situacao}}                    
                            </v-chip>
                          </td>

                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip outlined :color="getPago(row.item.total_reneg, row.item.total_pago)" >
                              {{getPagoDescricao(row.item.total_reneg, row.item.total_pago)  }}           
                            </v-chip>
                          </td>

                          

                        </tr>

                      
                         <!-- Nova linha para vencimento_original -->
                        <tr style="font-size:14px;">
                          <td style="text-align:right;font-size:12px;">
                            Parcela: #{{row.index + 1}} 
                          </td>
                          <td style="text-align:center;font-size:12px;">
                            {{row.item.ref}}
                          </td>
                          <td style="text-align:center;font-size:12px;">
                            {{ formatarData(row.item.vencimento_original) }}
                          </td>

                          <td style="text-align:center;font-size:12px;">
                          {{
                                  parseFloat(row.item.parcela_orignal).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })
                                }}
                          </td>
                          
                          <td colspan="4" style="text-align:left;font-size:12px;">
                              <v-chip v-if="row.item.obs !== ''"
                                  class="ma-2"
                                  color="pink" style="height:45px"
                                  label
                                  text-color="white"
                                >
                                  <v-icon left>
                                    mdi-label
                                  </v-icon>
                                <span  v-html="formatarObs(row.item.obs)"></span>
                              </v-chip>
                
                          </td>
                          
                        </tr>


                      </template>
              </v-data-table>

 <br/>
  
      <div class="d-flex justify-end" style="color:green; font-size: 48px; margin-right: 12px;">
        <span @click="download_xls_acordo_composicao" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
      </div>

    <br/>

  

          </base-material-card>
  
    </v-col>

  

  </v-row>

         <br/> <br/> <br/>
        </v-row>  
       
      </div>
   </v-tab-item>
   
    
    <v-tab-item value="tab-3">
        <div>
          <v-row>
  
            <v-col
            cols="12"
            sm="6"
            lg="4">
  
            <base-material-stats-card-s elevation="1"
            color="green" 
            icon="mdi-barcode-scan"
            subIcon="mdi-information"
            subIconColor="green"
            title="Boletos - CPF"
            :sub-text="'Boletos Ativos de Clientes sem CPF'"
            :valuex="this.bool_05"
            :value="this.boletos_cliente_sem_cpf"
            :downloadFunction="lista_boletos_ativos_sem_cpf_xls"
            />
            </v-col>

            <v-col
            cols="12"
            sm="6"
            lg="4">
  
            <base-material-stats-card-s elevation="1"
            color="orange" 
            icon="mdi-barcode-scan"
            subIcon="mdi-information"
            subIconColor="orange"
            title="Boletos - Endereço"
            :sub-text="'Boletos Ativos de Clientes sem Endereço'"
            :valuex="this.bool_06"
            :value="this.boletos_cliente_sem_endereco"
            :downloadFunction="lista_boletos_ativos_sem_endereco_xls"
            />
            </v-col>

           
          </v-row>  
         
        </div>
     
    </v-tab-item>

  </v-tabs-items>







   


  </v-container>

</v-app> 
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import FiltroEmpresa from "./components/FiltroEmpresa.vue"; // Certifique-se de que o caminho esteja correto

  import Cobranca from '../../services/cobranca'

  import Auditoria from '../../services/auditoria' 

  
  import { ContentLoader } from 'vue-content-loader'

  export default {
   
    name: 'Auditoria',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent, FiltroEmpresa,
      ContentLoader,
    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },



    mounted() {
     this.dashboard_contratos();
     this.dashboard_faturamento();
    },

   
   

    data () {
      return {


        clientes_falecidos_header: [
          { text: 'Id', value: 'idCliente' },
          { text: 'Nome', value: 'nome' },
          { text: 'Contrato', value: 'contrato' },
          { text: 'Situação', value: 'situacaoContrato' },
          { text: 'Nascimento', value: 'nascimento' },
          { text: 'Falecimento', value: 'falecimento' },

        ],

        lista_composicao: [],

          lista_composicao_header: [
          { text: 'Acordo', value: 'id' },
          { text: 'Data', value: 'data_acordo' },
                    { text: 'Total Original', value: 'soma_parcela_original' },
          { text: 'Total Renegociado', value: 'total_reneg' },
          { text: 'Parcela Original', value: 'vencimento_original' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Parcelas', value: 'parcelamento' },
          { text: '', value: '' },
          { text: '', value: '' },


          ],
        clientes_falecidos: [],
      
        boletos_cliente_sem_cpf:0,
        boletos_cliente_sem_endereco: 0,
        total_ativo_parcela_vencer: 0,
        total_titular_falecido: 0,
        total_problemas_doc: 0,
        total_servicos_carencia: 0,
        total_caixa_aberto: 0,
        total_pago_sem_juros: 0,
        total_ticket_aberto: 0,
        total_pagamento_menor: 0,

        showProgress: false,
        search: "",
        bool_01 : true,
        bool_02 : true,
        bool_03: true,
        bool_04: true,
        bool_05: true,


        bool_06: true,
        bool_07: true,
        bool_08: true,
        bool_09: true,
        bool_10: true,
        bool_11: true,
        bool_12: true,
        bool_13: true,
        bool_14: true,
        

        tab: 0, 

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Campanha 50 Anos",
          selectMode: "Pagamento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas","Campanha 50 Anos"],
          modos: ["Pagamento"],
          categoria: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PARQUE DAS FLORES", "PREVIDA", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos e Cemiteriais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

        
      
      }
    },

   
    methods: {

        lista_ativos_parcelas_vencer_xls() {
             
             this.showProgress = true;
         
             Auditoria.lista_ativos_parcelas_vencer_xls(this.selectedCompany)
             .then(response => {
                      if (response.status === 200) {

                        const fileName = `contratos_vencer_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()

                        }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });
        },

        lista_contratos_pago_sem_juros() {
             
             this.showProgress = true;
         
             Auditoria.lista_contratos_pago_sem_juros(this.selectedCompany)
             .then(response => {
                      if (response.status === 200) {

                        const fileName = `contratos_vencer_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()

                        }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });
        },

        lista_boletos_ativos_sem_cpf_xls(){
          this.showProgress = true;    
          Auditoria.lista_boletos_ativos_sem_cpf_xls(this.selectedCompany)
          .then(response => {
                    if (response.status === 200) {
                    
                            const fileName = `auditoria_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()
                        
                    }
                  })
                  .catch(error => {
                    // Lida com erros
                    console.error('Erro ao baixar o arquivo Excel:', error);
                  }) .finally(() => {
                    // Após o término do download, oculte o diálogo de progresso
                    this.showProgress = false;
                  });
        },

        lista_boletos_ativos_sem_endereco_xls(){
        this.showProgress = true; 
        Auditoria.lista_boletos_ativos_sem_endereco_xls(this.selectedCompany)
                    .then(response => {
                              if (response.status === 200) {
                              
                                      const fileName = `auditoria_${Date.now()}.xlsx`;
                                      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                      const fileLink = document.createElement('a')
                                      fileLink.href = fileURL
                                      fileLink.setAttribute('download', fileName)
                                      document.body.appendChild(fileLink)
                                      fileLink.click()
                                  
                              }
                            })
                            .catch(error => {
                              // Lida com erros
                              console.error('Erro ao baixar o arquivo Excel:', error);
                            }) .finally(() => {
                              // Após o término do download, oculte o diálogo de progresso
                              this.showProgress = false;
                            });
        },

        lista_servico_carencia_xls(){
        this.showProgress = true; 
        Auditoria.lista_servico_carencia_xls(this.selectedCompany)
                    .then(response => {
                              if (response.status === 200) {
                              
                                      const fileName = `auditoria_servico${Date.now()}.xlsx`;
                                      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                      const fileLink = document.createElement('a')
                                      fileLink.href = fileURL
                                      fileLink.setAttribute('download', fileName)
                                      document.body.appendChild(fileLink)
                                      fileLink.click()
                                  
                              }
                            })
                            .catch(error => {
                              // Lida com erros
                              console.error('Erro ao baixar o arquivo Excel:', error);
                            }) .finally(() => {
                              // Após o término do download, oculte o diálogo de progresso
                              this.showProgress = false;
                            });
         },

        lista_problemas_doc_xls(){
        this.showProgress = true; 
        Auditoria.lista_problemas_doc_xls(this.selectedCompany)
                    .then(response => {
                              if (response.status === 200) {
                              
                                      const fileName = `auditoria_problemas${Date.now()}.xlsx`;
                                      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                      const fileLink = document.createElement('a')
                                      fileLink.href = fileURL
                                      fileLink.setAttribute('download', fileName)
                                      document.body.appendChild(fileLink)
                                      fileLink.click()
                                  
                              }
                            })
                            .catch(error => {
                              // Lida com erros
                              console.error('Erro ao baixar o arquivo Excel:', error);
                            }) .finally(() => {
                              // Após o término do download, oculte o diálogo de progresso
                              this.showProgress = false;
                            });
        },

        lista_titular_falecido_xls(){

          this.showProgress = true;              
          
          Auditoria.lista_titular_falecido_xls(this.selectedCompany)
          .then(response => {
                    if (response.status === 200) {
                    
                            const fileName = `auditoria_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()
                        
                    }
                  })
                  .catch(error => {
                    // Lida com erros
                    console.error('Erro ao baixar o arquivo Excel:', error);
                  }) .finally(() => {
                    // Após o término do download, oculte o diálogo de progresso
                    this.showProgress = false;
                  });
        },

        lista_caixa_aberto_xls(){
            this.showProgress = true;              

            Auditoria.lista_caixa_aberto_xls(this.selectedCompany)
            .then(response => {
                      if (response.status === 200) {
                      
                              const fileName = `auditoria_${Date.now()}.xlsx`;
                              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                              const fileLink = document.createElement('a')
                              fileLink.href = fileURL
                              fileLink.setAttribute('download', fileName)
                              document.body.appendChild(fileLink)
                              fileLink.click()
                          
                      }
                    })
                    .catch(error => {
                      // Lida com erros
                      console.error('Erro ao baixar o arquivo Excel:', error);
                    }) .finally(() => {
                      // Após o término do download, oculte o diálogo de progresso
                      this.showProgress = false;
                    });
            },

       lista_ticket_aberto_xls(){
            this.showProgress = true;              

            Auditoria.lista_ticket_aberto_xls(this.selectedCompany)
            .then(response => {
                      if (response.status === 200) {
                      
                              const fileName = `auditoria_ticket_${Date.now()}.xlsx`;
                              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                              const fileLink = document.createElement('a')
                              fileLink.href = fileURL
                              fileLink.setAttribute('download', fileName)
                              document.body.appendChild(fileLink)
                              fileLink.click()
                          
                      }
                    })
                    .catch(error => {
                      // Lida com erros
                      console.error('Erro ao baixar o arquivo Excel:', error);
                    }) .finally(() => {
                      // Após o término do download, oculte o diálogo de progresso
                      this.showProgress = false;
                    });
            },
 
       lista_pagamento_menor_xls(){
            this.showProgress = true;              

            Auditoria.lista_pagamento_menor_xls(this.selectedCompany)
            .then(response => {
                      if (response.status === 200) {
                      
                              const fileName = `auditoria_pagamento_${Date.now()}.xlsx`;
                              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                              const fileLink = document.createElement('a')
                              fileLink.href = fileURL
                              fileLink.setAttribute('download', fileName)
                              document.body.appendChild(fileLink)
                              fileLink.click()
                          
                      }
                    })
                    .catch(error => {
                      // Lida com erros
                      console.error('Erro ao baixar o arquivo Excel:', error);
                    }) .finally(() => {
                      // Após o término do download, oculte o diálogo de progresso
                      this.showProgress = false;
                    });
            },


      formatarData(data) {
          if (!data) return ''; // Verificar se a data não está vazia ou indefinida

          // Dividir a data em ano, mês e dia
          const partes = data.split('-');
          if (partes.length !== 3) return data; // Se a data não estiver no formato esperado, retornar a data original

          // Montar a data no formato desejado (dd/mm/aaaa)
          const dia = partes[2];
          const mes = partes[1];
          const ano = partes[0];
          return `${dia}/${mes}/${ano}`;
        },


      filterData(valores) {
         this.isLoading = true;
         let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectedStartDate = valores.selectedStartDate2;
          this.selectedEndDate = valores.selectedEndDate2;
          this.dashboard_cobranca();

      },

      filterDataEmpresa(valores) {
         this.isLoading = true;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.dashboard_contratos();

      },

      
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },


     


      formatarMoeda(valor) {

        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
      },

    

      formatarObs(obs) {
              // Substituir \n por <br> para exibir corretamente no HTML
              return obs.replace(/\n/g, '<br>');
            },


      getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

    getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
            }
      },

     getPago(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'green lighten-1';
          } else {
              return 'red lighten-1';
          }
      },

      getPagoDescricao(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'Quitada';
          } else {
              return 'Em Aberto';
          }
      },

      getStatusC (status) {
            switch (status) {
            case 'Cancelado': return 'red darken-2'
            case 'Ativo' : return 'blue lighten-1'

              default: 'success'
            }
      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


    
    

      async dashboard_faturamento(){

        Auditoria.total_boletos_sem_endereco().then(response => {
                           if(response.status === 200){
                              this.boletos_cliente_sem_endereco = response.data;
                           } 
                         }).catch(e => {                      
                         }).finally(() => {
                          this.bool_06 = false
                       });

          Auditoria.total_boletos_sem_cpf().then(response => {
                          if(response.status === 200){
                            this.boletos_cliente_sem_cpf = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_05 = false
                      });


      },
          
      async dashboard_cobranca() {
 
        const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
        const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
        const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

         if (diferencaEmDias > 40) {

           this.isLoading = false;
           this.showFilter = true;
           this.showProgress = false;
           return 
         }

           Cobranca.acordo_composicao( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, "Todos", "Pagamento").then(response => {
             if(response.status === 200){
               this.lista_composicao = response.data;

             } 


           }).catch(e => {    
           }).finally(() => {
             
         });
      },

      async dashboard_contratos(){
     
        Auditoria.total_pago_sem_juros(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_pago_sem_juros = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_11 = false
                      });
      

          Auditoria.total_ativo_parcela_vencer(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_ativo_parcela_vencer = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                       
                          this.bool_07 = false
                      });
      
          Auditoria.total_titular_falecido(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_titular_falecido = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_08 = false
                      });

          Auditoria.total_problemas_doc(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_problemas_doc = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_09 = false
                      });

          Auditoria.total_servicos_carencia(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_servicos_carencia = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_10 = false
                      });
          
            Auditoria.total_caixa_aberto(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_caixa_aberto = response.data;
                          } 
                        }).catch(e => {   
                        }).finally(() => {
                          this.bool_12 = false
                      });

            Auditoria.lista_cliente_falecidos(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.clientes_falecidos = response.data;
                          } 
                        }).catch(e => {    
                        }).finally(() => {
                         
                      });

            Auditoria.total_ticket_aberto(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_ticket_aberto = response.data;
                          } 
                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_13 = false;
                      });

            Auditoria.total_pagamento_menor(this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.total_pagamento_menor = response.data;
                          } 
                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_14 = false;
                      });




                      


      },
    
    },

     

  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

  function getMonthName(monthNumber) {
    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
}

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }

  function formatMonthName(month) {
    const monthNames = [
        "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];

    if (month >= 1 && month <= 12) {
        return monthNames[month - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
  }


</script>

<style lang="css">
.custom-tab {
  color: blue;
}


</style>
